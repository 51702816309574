import Images from "../../assets/images";
import { formatMessage } from "../../utils/formatMessage";

export const items = [
  {
    image: Images.AnjoApp,
    title: formatMessage("carousel_anjo_title"),
    text: formatMessage("carousel_anjo_text"),
  },
  {
    image: Images.HygiaApp,
    title: formatMessage("carousel_hygia_title"),
    text: formatMessage("carousel_hygia_text"),
  },
  {
    image: Images.CCGSite,
    title: formatMessage("carousel_ccg_title"),
    text: formatMessage("carousel_ccg_text"),
  },
];
