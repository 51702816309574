import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../../../components/Button";
import useIsMobile from "../../../../hooks/useIsMobile";
import background from "../../assets/background.webp";
import backgroundMobile from "../../assets/backgroundMobile.webp";
import styles from "./styles.module.scss";

function Banner() {
  const intl = useIntl();
  const { isMobile } = useIsMobile();

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>
            <FormattedMessage
              id="home_banner_title"
              defaultMessage="Unindo criatividade e tecnologia para criar soluções digitais de alto impacto"
            />
          </h1>
          <h2 className={styles.text}>
            <FormattedMessage
              id="home_banner_description"
              defaultMessage="Como fábrica de softwares, criamos soluções digitais personalizadas para ajudar as empresas a alcançarem seus objetivos e as pessoas a viverem de maneira mais fácil e conveniente. Transforme suas ideias em realidade no mundo digital."
            />
          </h2>
          <a href="#contact">
            <Button
              width="250px"
              text={intl.formatMessage({
                id: "home_banner_button",
              })}
            >
              <FaArrowRight />
            </Button>
          </a>
        </div>
      </div>
      <img
        src={isMobile ? backgroundMobile : background}
        alt=""
        className={styles.background}
        loading="lazy"
      />
    </section>
  );
}

export default Banner;
