import React, { useState, useEffect } from "react";
import axios from "axios";
import { useIntl } from "react-intl";
import Button from "../../../../components/Button";
import styles from "./styles.module.scss";

function Blog() {
  const intl = useIntl();
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    if (blogPosts.length === 0) {
      const endpoints = [
        "https://blog.starta.site/wp-json/wp/v2/posts?page=1",
        "https://blog.starta.site/wp-json/wp/v2/Categories",
        "https://blog.starta.site/wp-json/wp/v2/Media",
        "https://blog.starta.site/wp-json/wp/v2/Users",
      ];

      axios.all(endpoints.map((promise) => axios.get(promise))).then(
        axios.spread((posts, categories, medias, tags) => {
          posts.data.map((post) => {
            const media = medias.data.find((media) => media.post === post.id);
            const category = categories.data.find(
              (category) => category.id === post.categories[0]
            );
            blogPosts.push({
              id: post.id,
              title: post.title.rendered,
              link: post.link,
              category: category.name,
              image: media?.source_url,
            });
            setBlogPosts([...blogPosts]);
          });
        })
      );
    }
  }, []);

  return (
    <section className={styles.container}>
      <h3 className={styles.title}>Blog</h3>
      <div className={styles.posts}>
        {blogPosts.map(
          (post, index) =>
            index <= 2 && (
              <a
                className={styles.card}
                href={post.link}
                target="_blank"
                rel="noreferrer"
                key={index}
              >
                <img src={post.image} alt="" loading="lazy" />
                <div className={styles.content}>
                  <p className={styles.info}>{post.number}</p>
                  <h4 className={styles.subtitle}>{post.title}</h4>
                  <div className={styles.category}>{post.category}</div>
                </div>
              </a>
            )
        )}
      </div>

      <a
        href="https://blog.starta.site/"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          width="190px"
          text={intl.formatMessage({
            id: "home_blog_button",
          })}
        />
      </a>
    </section>
  );
}

export default Blog;
