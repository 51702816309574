import { formatMessage } from "../../../../utils/formatMessage";

import Images from "../../assets";

export const cases = [
  {
    title: "Anjo",
    text: formatMessage("cases_anjo_description"),
    image: Images.Anjo,
  },
  {
    title: "Hygia",
    text: formatMessage("cases_hygia_description"),
    image: Images.Hygia,
  },
  {
    title: "CCG",
    text: formatMessage("cases_ccg_description"),
    image: Images.CCG,
  },
  {
    title: "Sicredi",
    text: formatMessage("cases_sicredi_description"),
    image: Images.Sicredi,
  },
  {
    title: "Tokyo Marine",
    text: formatMessage("cases_tokyo_description"),
    image: Images.Tokyo,
  },
  {
    title: "Tecredi",
    text: formatMessage("cases_tecredi_description"),
    image: Images.Tecredi,
  },
];
