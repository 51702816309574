import React from "react";
import CountUp from "react-countup";
import { useIntl } from "react-intl";
import Icons from "../../../../assets/icons";
import styles from "./styles.module.scss";

function Stats() {
  const intl = useIntl();
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={`${styles.item} ${styles.image}`}>
          <img src={Icons.Map} alt="" />
        </div>
        <div className={styles.items}>
        <div className={styles.item}>
          <p className={styles.number}>
            +<CountUp end={80} />
          </p>
          <p className={styles.text}>
            {intl.formatMessage({
              id: "home_stats_data_1",
            })}
          </p>
        </div>
        <div className={styles.item}>
          <p className={styles.number}>
            +<CountUp end={20} /> mi
          </p>
          <p className={styles.text}>
            {intl.formatMessage({
              id: "softwarefactory_stats_projects",
            })}
          </p>
        </div>
        <div className={styles.item}>
          <p className={styles.number}>
            +<CountUp end={1} /> mi
          </p>
          <p className={styles.text}>
            {intl.formatMessage({
              id: "home_stats_data_2",
            })}
          </p>
        </div>
        </div>
      </div>
    </section>
  );
}

export default Stats;
