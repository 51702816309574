import routesPaths from "../../routes/routesPaths";
import { formatMessage } from "../../utils/formatMessage";

export const submenu = [
  {
    label: formatMessage("navbar_startaProducts", "Produtos Starta"),
    link: routesPaths.products,
  },
  {
    label: "Outsourcing",
    link: routesPaths.outsourcing,
  },
  {
    label: formatMessage("navbar_softwarefactory", "Fábrica de Software"),
    link: routesPaths.softwareFactory,
  },
];

export const menu = [
  {
    label: formatMessage("navbar_home", "Home"),
    link: routesPaths.home,
  },
  {
    label: formatMessage("navbar_about", "Sobre"),
    link: routesPaths.about,
  },
  {
    label: formatMessage("navbar_products", "Soluções"),
    link: routesPaths.products,
    submenu: submenu,
  },
  {
    label: formatMessage("navbar_cases", "Cases"),
    link: routesPaths.cases,
  },
  {
    label: formatMessage("navbar_careers", "Carreiras"),
    link: routesPaths.careers,
  },
  {
    label: formatMessage("navbar_blog", "Blog"),
    link: 'https://blog.starta.site',
  },
];
